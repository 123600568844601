import { EXAM } from "constants/paths";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {getCategories, getTickets, startWorkout, WorkoutId} from "./redux";
import {
  WorkoutListWrapper,
  WorkoutItemBlock,
  WorkoutName,
  WorkoutCount,
} from "./styledComponents";
import {useCallback, useEffect, useState} from "react";
import { TicketsResponseItem } from "../../api/models/ticket";
import { getRightEnding } from "utils/formatDuration";
import styled from "styled-components";

type WorkoutItemType = {
  name: string;
  count: number;
  item: TicketsResponseItem;
};

const Workout = () => {
  const tickets = useSelector(getTickets);
  const categories = useSelector(getCategories);


  const [active, setActive] = useState( {name: 'AB', id: 1})

  useEffect(() => {
    if (categories.length > 0) {
      setActive(categories[0])
    }
  }, [categories]);


  return (
      <>
        <Head>

          <Content>
            {categories.map((item,i) =>
                <Button onClick={() => setActive(item)} active={item.name === active?.name}
                        isLeft={item.name === active.name && active?.name === 'CD'  ? 1 : 0}
                        isRight={item.name === active.name && active?.name === 'AB'? 1 : 0}
                        key={item.id}>{item.name}</Button>)}
          </Content>
        </Head>
        <WorkoutListWrapper>
          {tickets.filter((item) => item.category.id === active.id).map((item, index) => (
              <WorkoutItem
                  name={`Билет №${item.number}`}
                  item={item}
                  count={0}
                  key={index}
                  categoryId={item.category.id}
              />
          ))}
        </WorkoutListWrapper>
      </>
  );
};

const WorkoutItem = ({ name, categoryId, item: { count, number } }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleStartWorkout = useCallback((id: number) => {
    const workoutId: WorkoutId = `ticket_${id}_id`;
    dispatch(startWorkout({ id: workoutId, categoryId: categoryId }));
    history.push(`${EXAM}?type=workout&ticket=${id}&category=${categoryId}`);
  }, [categoryId]);

  return (
    <WorkoutItemBlock onClick={() => handleStartWorkout(number)}>
      <WorkoutName>{name}</WorkoutName>
      {count ? (
        <WorkoutCount>Пройдено: {getRightEnding(count)}</WorkoutCount>
      ) : null}
    </WorkoutItemBlock>
  );
};

export default Workout;


const Button = styled.button<{active: boolean,isRight: number, isLeft: number}>`
background: ${({active}) => active ? '#fff' : '#F7F8FA'};
  padding: 5px 75px;
  border-radius: 8px;
  width: 250px;
  border: 1px solid #DADDE4;
  border-left-width: ${({isLeft}) => isLeft};;
  border-right-width: ${({isRight}) => isRight};;
  border-top-width: 0;
  border-bottom-width: 0;
`

const Head = styled.div`
  display: flex;
  margin-bottom: 15px;
;`

const Content = styled.div`
    background: #F7F8FA;
    border-radius: 8px;
  border: 1px solid #DADDE4;

`
