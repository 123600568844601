import { useState, useEffect } from "react";
import { Button } from "components";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {getCategories, getExam} from "./redux";
import { useHistory } from "react-router";
import { EXAM } from "constants/paths";
import { getExamStart } from "../../api/PddTicketAPI";

const StarterWrapper = styled.div`
  margin: 243px auto;

  @media screen and (max-width: 910px) {
    margin: 15px auto;
  }
`;

const StarterTitle = styled.p`
  text-align: center;
  font-size: 1.875em;
  font-weight: 600;
  margin: 0 0 24px;
`;
const StarterDescription = styled.p`
  text-align: center;
  max-width: 510px;
  margin: auto auto 40px;
`;
const StartButton = styled(Button)`
  display: block;
  margin: auto;
  width: 137px;
  height: 40px;
`;

const StartExam = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const exam = useSelector(getExam);
  const dispatch = useDispatch();
  const categories = useSelector(getCategories);


  const [active, setActive] = useState( {name: 'AB', id: 1})

  useEffect(() => {
    if (categories.length > 0) {
      setActive(categories[0])
    }
  }, [categories]);

  const handleStartExam = useCallback((category) => {
    setLoading(true);
    dispatch(getExamStart(active.id));
  }, [dispatch, active]);

  useEffect(() => {
    if (exam) {
      setLoading(false);
      history.push(`${EXAM}?type=exam`);
    }
  }, [exam]);

  return (
    <StarterWrapper>
      <StarterTitle>Экзамен по ПДД 2024</StarterTitle>
      <StarterDescription>
        Экзаменационный билет состоит из 20 вопросов. У Вас будет всего 20 минут
        что бы ответить на все вопросы. Максимальное количество ошибок - 2, за
        каждую добавляются по 5 вопросов, ответив на которые, ошибки считаются
        исправленными.
      </StarterDescription>
      <Head>

        <Content>
          {categories.map((item,i) =>
              <ButtonSwich onClick={() => setActive(item)} active={item.name === active?.name}
                      isLeft={item.name === active.name && active?.name === 'CD'  ? 1 : 0}
                      isRight={item.name === active.name && active?.name === 'AB'? 1 : 0}
                      key={item.id}>{item.name}</ButtonSwich>)}
        </Content>
      </Head>
      <StartButton disabled={loading} onClick={() => handleStartExam(active.id)}>
        Начать
      </StartButton>
    </StarterWrapper>
  );
};

export default StartExam;



const ButtonSwich = styled.button<{active: boolean,isRight: number, isLeft: number}>`
background: ${({active}) => active ? '#fff' : '#F7F8FA'};
  padding: 5px 75px;
  border-radius: 8px;
  width: 250px;
  border: 1px solid #DADDE4;
  border-left-width: ${({isLeft}) => isLeft};;
  border-right-width: ${({isRight}) => isRight};;
  border-top-width: 0;
  border-bottom-width: 0;
`

const Head = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
;`

const Content = styled.div`
    background: #F7F8FA;
    border-radius: 8px;
  border: 1px solid #DADDE4;

`
